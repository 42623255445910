import React from 'react';

function DefaultLayout({ children }) {
	return (
		<div>
			<main>
				{children}
			</main>
		</div>
	);
}

export default DefaultLayout;
