import { useRef } from 'react';

const safeDocument = typeof document !== 'undefined' ? document : {};

/**
 * Usage:
 * const [blockScroll, allowScroll] = useScrollBlock();
 */
const useScrollBlock = () => {
	const scrollBlocked = useRef();
	const html = safeDocument.documentElement;
	const { body } = safeDocument;

	const blockScroll = () => {
		if (!body || !body.style || scrollBlocked.current) return;

		const scrollBarWidth = window.innerWidth - html.clientWidth;
		const bodyPaddingRight =
			Number(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

		/**
		 * 1. Fixes a bug in iOS and desktop Safari whereby setting
		 *    `overflow: hidden` on the html/body does not prevent scrolling.
		 * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
		 *    scroll if an `overflow-x` style is also applied to the body.
		 */
		html.style.setProperty('position', 'relative', 'important');
		html.style.setProperty('overflow', 'hidden', 'important');
		html.style.setProperty('overflowY', 'hidden', 'important');
		body.style.setProperty('position', 'relative', 'important');
		body.style.setProperty('overflowY', 'hidden', 'important');
		body.style.setProperty('overflow', 'hidden', 'important');
		// body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

		scrollBlocked.current = true;
	};

	const allowScroll = () => {
		if (!body || !body.style || !scrollBlocked.current) return;

		html.style.setProperty('position', '', 'important');
		html.style.setProperty('overflow', 'auto', 'important');
		html.style.setProperty('overflowY', 'auto', 'important');

		body.style.setProperty('position', '', 'important');
		body.style.setProperty('overflow', 'auto', 'important');
		body.style.setProperty('overflowY', 'auto', 'important');

		// body.style.paddingRight = '';

		scrollBlocked.current = false;
	};

	return [blockScroll, allowScroll];
};
export default useScrollBlock;
